import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [PDf, setPDF] = React.useState(FetchData.meta.book_pdf)
  const [Author, setAuthor] = React.useState(FetchData.meta.book_author)
  const [PublishedDate, setPublishedDate] = React.useState(FetchData.meta.book_published_date)
  const [AboutBook, setAboutBook] = React.useState(FetchData.meta.about_book)
  
  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
 
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status":"publish",
      "meta": {
        "book_name": Title,
        "book_pdf": PDf,
        "book_author": Author,
        "book_published_date": `${PublishedDate}`,
        "about_book": AboutBook
      }
    }
   
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_book)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.books_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_book, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Book Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Pdf</td>
                <td className="col-6">
                  <a href={PDf} target="_blank">{PDf}</a>
                <FileUpload 
                  ImageUrl={PDf}
                  setImageUrl={setPDF}
                />
                </td>
              </tr>
              <tr>
                <td className="col-3">Author</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Author} placeholder="Author"
                    onChange={(event) => { setAuthor(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Published Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PublishedDate} placeholder="Published Date"
                    onChange={(event) => { setPublishedDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">About Book</td>
                <td className="col-6">
                  <textarea cols={5} className="input-common"
                    value={AboutBook} placeholder="About Book"
                    onChange={(event) => { setAboutBook(event.target.value) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Book
              </div>
            </div>
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}